@import "bootstrap/index";
@import "flags";

.header-huge, .header-moderate {
    background: url(../images/got2.jpg) no-repeat center center scroll;
    background-size: cover;
    box-shadow: 0 0 10px rgba(0,0,0,0.8);
    display: table;
    position: relative;
    width: 100%;
}

.header-huge {
    height: 400px;
}

.header-moderate {
    height: 250px;
}

section .container{
    background-color: rgba(0, 100, 50, .01);
}

article {
    .lead;
}

section p {
    .lead;
}

footer p {
    .text-muted;
    margin: 10px;
}

html,
body {
    width: 100%;
    height: 100%;
}

body {
    padding-top: 50px;
    background: #FFFFFF;
}

.starter-template {
    padding: 40px 15px;
    text-align: center;
}

#map-canvas {
    height: 200px;
}

.maps iframe {
    pointer-events: none;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

html {
    position: relative;
    min-height: 100%;
}

body {
    margin-bottom: 100px;
}

.container .text-muted {
    margin: 20px 0;
}

a:link {
    color: #9D9D9D;
}

a:visited {
    color: #9D9D9D;
}

a.btn {
    color: white;
}

.fill{
    width:100%;
    height:100%;
    min-height:100%;
    padding:10px;
}

header img {
    max-width: 300px;
    min-width: 25%;
}

.text-vertical-center {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.text-vertical-center h1 {
    margin: 0;
    padding: 0;
    font-size: 4.5em;
    font-weight: 700;
}

.info {
    background-color:rgb(223,240,216);
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

.info p {
    margin: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.info div {
    border: 1px solid rgba(0,40,0,0.2);
}

.some-links {
    margin-left: 0;
    list-style-type: none;

    li {
        display: inline;
        margin-left: 1em;
    }
}

.space-above {
    margin-top: 1.5em;
}



// embedded programme schedule
.timetable td.blank {
    border-top: none !important;
    border-bottom: none !important;
}

.timetable .incontinuity {
  border-top: 3pt dotted #ddd;
}

.programme-metadata {
  list-style-type: none;
  padding-left: 0;

  li {
    display: inline-block;
  }
}

.legend {
  float: right;
}

.legend .categories {
  margin: 0;
  padding: 0;
}

.legend .categories li {
  display: inline-block;
  list-style-type: none;
  margin-left: 3px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.meta {
  font-style: italic;
  color: #808080;
  font-size: 90%;
}

.popover-title {
  font-weight: bold;
}

.timetable a {
    color: white;
}

.timetable .muu {
    background-color: #5eb95e;
}

.timetable .rope {
    background-color: #8058a5;
}

.timetable .anime {
    background-color: #0e90d2;
}

.timetable .cosplay {
    background-color: #dd514c;
}

.timetable .miitti {
    background-color: #f37b1d;
}

.timetable .sisainen {
    background-image: url('/static/stripe.png');
}

.timetable .hilight, .descriptions .hilight {
    background: url(jean_victor_balin_icon_star_24px.png) no-repeat;
    padding: 5px;
    display: inline-block;
    width: 24px;
    height: 24px;
    color: transparent; /* XXX hack */
}

.descriptions .hilight {
    vertical-align: bottom;
}

.descriptions .programme {
    margin: 0px 0px 20px 1px;
    padding-left: 8px;
}

.descriptions .muu {
    border-left: 5px solid #5eb95e;
}

.descriptions .rope {
    border-left: 5px solid #8058a5;
}

.descriptions .anime {
    border-left: 5px solid #0e90d2;
}

.descriptions .cosplay {
    border-left: 5px solid #dd514c;
}

.descriptions .miitti {
    border-left: 5px solid #f37b1d;
}

.descriptions .sisainen {
    border-left: 5px solid #666;
}