/* from https://www.flag-sprites.com/ */
.flag {
    width: 16px;
    height: 11px;
    background:url(../images/flags.png) no-repeat;

    &.flag-fi {
        background-position: -16px 0;
    }

    &.flag-gb {
        background-position: 0 -11px;
    }
}
